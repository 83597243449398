.OuterBox{
    display: flex;
    width: 70%;
    background-color: rebeccapurple;
    margin-left: 100px;
    margin-bottom: 5px;
    height: 30px;

    
 
}

@media screen and (max-width: 1200px) {
  .OuterBox{
  display: flex;
  width: 90%;
  background-color: rgb(25, 14, 35);
  margin-left: 5px;
  margin-bottom: 5px;
  height: 30px;

  
  }
  }


.Title {
  
  line-height: 30px;
    flex: 1;
    background-color: rgb(90, 90, 122); /* Set background color for the title area */
  }
  
  .Link {
    line-height: 30px;
    flex: 1;
    background-color: lightgreen; /* Set background color for the link area */
  }