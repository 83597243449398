.OuterBoxInfo{
    display: flex;
    
   
    margin-left: 100px;
    margin-bottom: 15px;
    height: 135px;
  
 
}

@media screen and (max-width: 1350px) {




  .OuterBoxInfo{
    display: flex;
    
    
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    height: 160px;
    line-height: 0.9;
    
 
}


.Info {
  flex: 1;
  /* Set background color for the title area */
  text-align: center;
  display: flex;
justify-content: center; /* Horizontal centering */
align-items: center;
flex-direction: column;
}

}


.Info {
    flex: 1;
    background-color: lightblue; /* Set background color for the title area */
  
  }
  
  .Link2 {

    line-height: 135px;
    flex: 1;
    background-color: lightgreen; /* Set background color for the link area */
  }

  .WebLink {
    line-height: 135px;
    
    flex: 1;
    background-color: rgb(222, 127, 116); /* Set background color for the link area */

  }
  
  .Image {

    flex: 0.45;
     background-color: lightgray;
  }

  
.Image img {
    margin-top: 37.5px;
     /* Set a max height for the image */
    max-width: 100%;
    max-height: 100px;
  
    width: auto; /* Maintain the aspect ratio */
  }