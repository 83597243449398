.Education{

    z-index: 2
}


.Main {
    display: flex;
  
    flex-wrap: wrap;
    width: 100%;
 
}

@media screen and (max-width: 800px) {
    .Main {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        /* Additional styles for screens less than 500px wide go here */
    }}


.Child {
    flex: 1; /* Make child elements equally share the available space */
    
  }