.Hunter{
 

    display: flex;
    margin-right: 40px;
    
    
  }


  @media screen and (min-width: 1350px) {


    .Hunter{
 
      
      display: flex;
    
   
      margin-top: 100px;
    }

      
   
  }


  @media screen and (max-width: 1350px) {


    .Hunter{
 
      
      display: flex;
      
      height: 250px;
      line-height: 1.1;
    }

  
      
   
  }

.HunterPicture img{

width: 100%;
max-height: 100%;

}



.InfoHunter{
   
   
  
  
}